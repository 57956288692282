import { useState } from "react";
import * as S from "./LandingFooterMobile.style";
import { pushEvent } from "@tm/utility/analytics";
import { Image } from "../Image";

const LandingFooterMobile = ({
  mmtInitiativeLink,
  cityIcon,
  blogIcon,
  quickLinkIcon,
  otherIcon,
  cityTitle,
  blogTitle,
  quickLinkTitle,
  otherTitle,
  cities,
  blog,
  quickLinks,
  others,
  copyRightTitle,
  contryName,
  twitterIcon,
  fbIcon,
  linkedInIcon,
  playStoreIcon,
  appStoreIcon,
}: any) => {
  const [link, setLink] = useState(-1);

  const handleClick = (index: number) => {
    link != index ? setLink(index) : setLink(-1);
  };

  const onOtherLinkHandler = ({ linkEvent }: any) => {
    if (linkEvent) pushEvent(linkEvent);
  };

  return (
    <S.LandingFooter>
      <S.LandingFooterWrap>
        <S.LandingFooterImg>
          <Image {...mmtInitiativeLink} />
        </S.LandingFooterImg>
        {cityIcon && (
          <S.LandingFooterSub
            className={`${link == 0 ? "open" : "close"}`}
            onClick={() => handleClick(0)}
          >
            <S.IconMargin>
              <Image {...cityIcon} />
            </S.IconMargin>
            {cityTitle}
          </S.LandingFooterSub>
        )}
        {link == 0 && cities && (
          <S.LandingFooterList flexWrap={true} bdrProp={link == 0}>
            {cities?.map((item: any) => {
              return (
                <li>
                  <a href="#" aria-label="cities">
                    {item}
                  </a>
                </li>
              );
            })}
          </S.LandingFooterList>
        )}
        {blogTitle && (
          <S.LandingFooterSub
            className={`${link == 1 ? "open" : "close"}`}
            onClick={() => handleClick(1)}
          >
            <Image {...blogIcon} className="iconMargin" />
            {blogTitle}
          </S.LandingFooterSub>
        )}
        {link == 1 && blog && (
          <S.LandingFooterList bdrProp={link == 1}>
            {blog?.map((item: any) => {
              return (
                <li>
                  <a href="#" aria-label="blog">
                    {item}
                  </a>
                </li>
              );
            })}
          </S.LandingFooterList>
        )}
        {quickLinkTitle && (
          <S.LandingFooterSub
            className={`${link == 2 ? "open" : "close"}`}
            onClick={() => handleClick(2)}
          >
            <Image {...quickLinkIcon} className="iconMargin" />
            {quickLinkTitle}
          </S.LandingFooterSub>
        )}
        {link == 2 && quickLinks && (
          <S.LandingFooterList bdrProp={link == 2}>
            {quickLinks?.map(
              ({ text, linkEvent, href, target = "_self" }: any) => {
                return (
                  <li>
                    <a
                      href={href}
                      target={target}
                      aria-label="other"
                      onClick={() => onOtherLinkHandler({ linkEvent })}
                    >
                      {text}
                    </a>
                  </li>
                );
              }
            )}
          </S.LandingFooterList>
        )}
        {otherTitle && (
          <S.LandingFooterSub
            className={`${link == 3 ? "open" : "close"}`}
            onClick={() => handleClick(3)}
          >
            <S.IconMargin>
              <Image {...otherIcon} />
            </S.IconMargin>
            {otherTitle}
          </S.LandingFooterSub>
        )}
        {link == 3 && others && (
          <S.LandingFooterList bdrProp={link == 3}>
            {others?.map(({ text, linkEvent }: any) => {
              return (
                <li>
                  <a
                    href="#"
                    aria-label="other"
                    onClick={() => onOtherLinkHandler({ linkEvent })}
                  >
                    {text}
                  </a>
                </li>
              );
            })}
          </S.LandingFooterList>
        )}
        <S.LandingFooterFlex>
          <div>
            <S.Flex>
              <a
                href={twitterIcon?.redirectionUrl}
                aria-label="twitter"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() =>
                  onOtherLinkHandler({
                    linkEvent: twitterIcon?.linkEvent,
                  })
                }
              >
                <S.IconMargin>
                  <Image {...twitterIcon?.iconProps} />
                </S.IconMargin>
              </a>
              <a
                href={fbIcon?.redirectionUrl}
                aria-label="facebook"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() =>
                  onOtherLinkHandler({
                    linkEvent: twitterIcon?.linkEvent,
                  })
                }
              >
                <S.IconMargin>
                  <Image {...fbIcon?.iconProps} />
                </S.IconMargin>
              </a>
              <a
                href={linkedInIcon?.redirectionUrl}
                aria-label="linkedin"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() =>
                  onOtherLinkHandler({
                    linkEvent: twitterIcon?.linkEvent,
                  })
                }
              >
                <S.IconMargin>
                  <Image {...linkedInIcon?.iconProps} />
                </S.IconMargin>
              </a>
            </S.Flex>
          </div>
          <div>
            <S.Flex>
              <a href={playStoreIcon?.redirectionUrl} aria-label="googlePlay">
                <S.IconMargin>
                  <Image {...playStoreIcon} />
                </S.IconMargin>
              </a>
              <a href={appStoreIcon?.redirectionUrl} aria-label="appleStore">
                <Image {...appStoreIcon} />
              </a>
            </S.Flex>
          </div>
          {copyRightTitle && (
            <S.LandingFooterCopyRight>
              <span>{copyRightTitle}</span>
              <span>{contryName}</span>
            </S.LandingFooterCopyRight>
          )}
        </S.LandingFooterFlex>
      </S.LandingFooterWrap>
    </S.LandingFooter>
  );
};

export default LandingFooterMobile;
