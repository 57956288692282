import styled from "styled-components";

export const LandingFooter = styled.div`
  background: #000;
  color: #fff;
  font-size: 12px;
  line-height: 16px;
  padding-top: 36px;
  padding-bottom: 26px;
`;

export const LandingFooterSub = styled.div`
  font-weight: 900;
  font-size: 14px;
  line-height: 16px;
  color: #fff;
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  border-bottom: 1px solid #4a4a4a;
  padding: 22px 16px;
  position: relative;
  width: 100%;
  &.open {
    border-bottom: unset;
    margin-bottom: unset;
  }
  &.close:after {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
  &.open:after {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
  &:after {
    content: "";
    position: absolute;
    right: 16px;
    top: 23px;
    border: solid #ff664b;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transition: 0.3s ease-in;
  }
`;

export const LandingFooterWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LandingFooterImg = styled.div`
  width: 325px;
  height: 27px;
  margin-bottom: 20px;
`;

export const LandingFooterList = styled.ul<any>`
  display: flex;
  gap: 10px;
  margin-bottom: 26px;
  flex-direction: column;
  padding-left: 46px;
  width: 100%;
  flex-wrap: wrap;
  margin: ${(props) => props.margin};

  & li {
    position: static;
  }
  & li:last-child {
    position: static;
  }

  lia: hover {
    text-decoration: underline;
  }

  ${(props) =>
    props.bdrProp &&
    `
border-bottom:1px solid #4a4a4a;
      padding-bottom:20px;
      margin-bottom:unset;
    `}
  & li a {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;
  }
  &:last-child {
    margin-bottom: 100px;
  }
  ${(props) =>
    props.flexWrap &&
    `
height:198px;
flex-wrap:wrap;
    `}
`;

export const LandingFooterFlex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

export const Flex = styled.div`
  display: flex;
  margin-top: 1rem;
`;

export const IconMargin = styled.div`
  margin: 0 16px 0 0;
`;

export const LandingFooterCopyRight = styled.div`
  font-family: "Open Sans";
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  font-size: 10px;
  font-weight: 900;
  font-size: 12px;
  line-height: 14px;
  align-self: center;

  & span:nth-child(2) {
    font-weight: 600;
    margin-top: 3px;
  }
`;
